import PropTypes from 'prop-types'
import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useStaticQuery, graphql, Link } from 'gatsby'

import layerFlip from '../images/background/patternFlip.webp'
import Img from 'gatsby-image'
import back from '../images/background/3.png'
import hero from '../images/background/hero.svg'
import error from '../images/background/error.svg'
import success from '../images/background/success.svg'
import mentions from '../images/background/mentions.svg'

const Header = ({ title, desc, svg, redirect, desc2 }) => {
  const query = graphql`
    {
      back: file(relativePath: { eq: "logo.webp" }) {
        size
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `

  const data = useStaticQuery(query)
  const { fluid } = data.back.childImageSharp

  const renderSvg = () => {
    if (svg === 'error') {
      return (
        <object
          type="image/svg+xml"
          className="img-fluid"
          data={error}
        ></object>
      )
    } else if (svg === 'success') {
      return (
        <object
          type="image/svg+xml"
          className="img-fluid"
          data={success}
        ></object>
      )
    } else if (svg === 'mentions') {
      return (
        <object
          type="image/svg+xml"
          className="img-fluid pull-right"
          data={mentions}
        ></object>
      )
    } else {
      return (
        <object
          type="image/svg+xml"
          className="img-fluid mt-5"
          data={hero}
        ></object>
      )
    }
  }

  return (
    <>
      <div
        className=" layer-header"
        style={{
          backgroundImage: `url(${layerFlip})`,
          backgroundPosition: 'right',
        }}
        data-aos="fade-down"
        data-aos-duration="900"
      >
        <div
          style={{ backgroundImage: `url(${back})`, zIndex: '1 !important' }}
          className="banner-section-tms"
        >
          <nav className={`main-header navbar navbar-expand-lg navbar-dark`}>
            <div
              className={`navbar-collapse collapse w-100 dual-collapse2 order-1 order-md-0`}
            >
              <ul className="navbar-nav ml-auto text-center">
                <li
                  className="nav-item"
                  data-aos="fade-down"
                  data-aos-delay="300"
                  data-aos-duration="800"
                >
                  <AnchorLink className="nav-link" to="/#projects">
                    Projets
                  </AnchorLink>
                </li>
                <li
                  className="nav-item"
                  data-aos="fade-down"
                  data-aos-delay="200"
                  data-aos-duration="800"
                >
                  <AnchorLink className="nav-link" to="/#services">
                    Services
                  </AnchorLink>
                </li>
              </ul>
            </div>
            <div
              className="mx-auto my-2 order-0 order-md-1 position-relative"
              data-aos="fade-down"
              data-aos-delay="400"
              data-aos-duration="800"
            >
              <Link className="mx-auto navbar-brand logo" to="/">
                <Img fluid={fluid} alt="logo ds" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target=".dual-collapse2"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="navbar-collapse collapse w-100 dual-collapse2 order-2 order-md-2">
              <ul className="navbar-nav mr-auto text-center">
                <li
                  className="nav-item"
                  data-aos="fade-down"
                  data-aos-delay="200"
                  data-aos-duration="800"
                >
                  <AnchorLink className="nav-link" to="/#offer">
                    Offre
                  </AnchorLink>
                </li>
                <li
                  className="nav-item"
                  data-aos="fade-down"
                  data-aos-delay="300"
                  data-aos-duration="800"
                >
                  <AnchorLink className="nav-link" to="/#contact">
                    Contact
                  </AnchorLink>
                </li>
              </ul>
            </div>
          </nav>
          <section className="banner-section-two ekip" id="banner">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="content-column col-lg-6 col-md-12 col-sm-12">
                  <div
                    className="inner-column"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    data-aos-duration="800"
                  >
                    <div style={{ color: 'white' }}>
                      <h1 style={{ marginBottom: '0px' }}>{title}</h1>
                      <h2 style={{ fontSize: '18px' }}>{desc2} </h2>
                    </div>
                    <h2 style={{ color: 'white', marginTop:'25px' }}> {desc} </h2>
                    {redirect instanceof Function ? redirect() : ''}
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <figure
                      className="image"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="800"
                    >
                      {renderSvg()}
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
