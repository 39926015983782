import React from 'react'
import svg from '../images/background/footer.svg'
import { Link } from 'gatsby'

const footer = () => {
  return (
    <footer
      style={{
        marginTop: `2rem`,
        backgroundImage: `url(${svg})`,
      }}
      className="page-footer font-small cyan darken-3"
      data-aos="fade-in"
      data-aos-delay="400"
      data-aos-duration="800"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5">
            <div className="mb-5 flex-center">
              <a className="fb-ic"></a>
              <a className="tw-ic"></a>

              <a className="gplus-ic"></a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-copyright text-center py-3 mt-5">
        © {new Date().getFullYear()}
        <a href="https://agencelads.com/concepts"> Agence la DS</a>
        <br />
        <Link to="/legal" style={{ fontSize: '12px' }}>
          Mentions légales
        </Link>
        <br />
        <a
          href="https://www.facebook.com/agencelads/"
          className="project-link"
          target="_blank"
        >
          <i className="fa fa-facebook-square fa-lg"></i>
        </a>
        <a
          href="https://instagram.com/agencelads"
          className="project-link ml-3"
          target="_blank"
        >
          <i className="fa fa-instagram fa-lg"></i>
        </a>
      </div>
    </footer>
  )
}

export default footer
