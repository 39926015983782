import React, { useEffect, lazy } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

import AOS from 'aos'
import 'aos/dist/aos.css'

const Layout = ({ children, title, desc, svg, redirect, desc2 }) => {
  useEffect(() => {
    AOS.init({ disable: 'mobile' })
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0)
    }
  })

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        title={title} desc={desc} svg={svg} redirect={redirect} desc2={desc2} siteTitle={data.site.siteMetadata?.title || `Web | Agence la DS`}
      />
      <script src="https://unpkg.com/@popperjs/core@2"></script>
      <div>
        <main className="page-wrapper">{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
